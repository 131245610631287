<template>
  <div>
    <van-tabbar v-model="active" class="tabbar" @change="toggleTabBar" v-show="tabBarShow">
      <van-tabbar-item class="iconfont icon-fuwu" @click="toServer">服务</van-tabbar-item>
      <van-tabbar-item class="menubottom" :class="{'rotate':rotate}" @click="toggleMenu">
        <template>
          <img src="@/assets/images/menu.png" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item class="iconfont icon-yonghu" @click="toUser">个人中心</van-tabbar-item>
    </van-tabbar>
    <!-- 弹窗 -->
    <van-popup class="menuPop" v-model="popShow" position="bottom" round @close="closeMenu">
      <ul>
        <li @click="toReleaseHouse">
          <div>
            <img src="@/assets/images/menu1.png" />
          </div>
          <p>房屋租售</p>
        </li>
        <li @click="showJobPop">
          <div>
            <img src="@/assets/images/menu2.png" />
          </div>
          <p>人才招聘</p>
        </li>
        <li @click="showFindJobPop">
          <div>
            <img src="@/assets/images/menu3.png" />
          </div>
          <p>求职简历</p>
        </li>
        <li @click="showPromotePop">
          <div>
            <img src="@/assets/images/menu4.png" />
          </div>
          <p>推广产品</p>
        </li>
      </ul>
    </van-popup>
    <!-- 招聘弹窗 -->
    <van-popup v-model="jobShow" class="jobPop" position="bottom" round @close="closeJob">
      <van-loading color="#1989fa" type="spinner" class="dataLoading" v-show="loading" />
      <div v-show="!loading">
        <div class="jobt">
          <div class="r">{{popTitle}}</div>
        </div>
        <div class="popBody">
          <div
            class="jobItem"
            v-for="(item,index) in jobList"
            :key="index"
            @click="toFullTime(item)"
          >{{item.text}}</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: true,
      popTitle: "发布全职招聘信息",
      menuType: "", //发布菜单类型
      jobPopType: "", //检测job弹窗是全职招聘or求职
      menuClose: true,
      jobClose: true,
      jobShow: false, //全职招聘弹窗
      rotate: false,
      popShow: false, //tabbar 菜单弹窗
      show: false,
      tabBarShow: true,
      actived: this.tabbarActive,
      active: this.tabbarActive,
      jobList: []
    };
  },
  props: ["tabbarActive"],
  created() {},
  methods: {
    getPlate() {
      //获取类别
      this.jobList = [];
      this.$request({
        method: "get",
        url: "/cms/plate",
        params: {
          type: this.menuType
        }
      }).then(res => {
        this.loading = false
        if (res.data && res.data.length) {
          res.data.forEach(item => {
            let objdata = `{
                "id": "${item.id}",
                "text": "${item.title}"
               }`;
            this.jobList.push(JSON.parse(objdata));
          });
        }
      });
    },
    toServer() {
      if (this.$route.path !== "/Home") {
        this.$router.push("/Home");
      }
    },
    toUser() {
      if (this.$route.path !== "/User") {
        this.$router.push("/User");
      }
    },
    toggleTabBar() {
      this.active = this.actived;
    },
    showPop() {
      this.show = true;
      this.tabBarShow = false;
      this.jobShow = false;
    },
    toReleaseHouse() {
      //发布房屋出售
      this.menuType = "1";
      this.jobShow = true;
      this.popShow = false;
      this.jobClose = false;
      this.jobPopType = "House";
      this.popTitle = "发布房屋租售信息";
      this.getPlate();
    },
    showJobPop() {
      // 显示全职招聘弹窗
      this.menuType = "2";
      this.jobShow = true;
      this.popShow = false;
      this.jobClose = false;
      this.jobPopType = "Recrut";
      this.popTitle = "发布人才招聘信息";
      this.getPlate();
    },
    showFindJobPop() {
      // 显示求职弹窗
      this.menuType = "3";
      this.jobShow = true;
      this.popShow = false;
      this.jobClose = false;
      this.jobPopType = "FindJob";
      this.popTitle = "发布求职信息";
      this.getPlate();
    },
    showPromotePop(){
      // 显示求职弹窗
      this.menuType = "4";
      this.jobShow = true;
      this.popShow = false;
      this.jobClose = false;
      this.jobPopType = "Promote";
      this.popTitle = "发布推广信息";
      this.getPlate();
    },
    toggleMenu() {
      if (this.rotate) {
        this.rotate = false;
        this.popShow = false;
        this.jobShow = false;
      } else {
        this.rotate = true;
        this.popShow = true;
      }
    },
    closeMenu() {
      this.menuClose = true;
      if (this.menuClose && this.jobClose) {
        this.rotate = false;
      }
    },
    closeJob() {
      this.jobClose = true;
      if (this.menuClose && this.jobClose) {
        this.rotate = false;
      }
    },
    toFullTime(item) {
      if (this.jobPopType == "FindJob") {
        //发布求职信息
        this.$router.push({
          path: "/FindJob",
          query: {
            jobType: item.text,
            id: item.id
          }
        });
      } else if (this.jobPopType == "Recrut") {
        //发布全职招聘
        this.$router.push({
          path: "/Recruitment",
          query: {
            jobType: item.text,
            id: item.id
          }
        });
      } else if (this.jobPopType == "House") {
        //发布全职招聘
        if (
          item.id == "3" ||
          item.id == "7" ||
          item.id == "9" ||
          item.id == "17"
        ) {
          this.$router.push({
            path: "/House",
            query: {
              jobType: item.text,
              id: item.id
            }
          });
        } else if (item.id == "11" || item.id == "15") {
          this.$router.push({
            path: "/HouseA",
            query: {
              jobType: item.text,
              id: item.id
            }
          });
        } else if (item.id == "1") {
          this.$router.push({
            path: "/HouseB",
            query: {
              jobType: item.text,
              id: item.id
            }
          });
        } else if (item.id == "5") {
          this.$router.push({
            path: "/HouseC",
            query: {
              jobType: item.text,
              id: item.id
            }
          });
        } else if (item.id == "13") {
          this.$router.push({
            path: "/HouseD",
            query: {
              jobType: item.text,
              id: item.id
            }
          });
        }
      } else if (this.jobPopType == "Promote") {
        //发布全职招聘
        this.$router.push({
          path: "/Promote",
          query: {
            jobType: item.text,
            id: item.id
          }
        });
      }
    }
  }
};
</script>
<style scoped lang="scss">
@import "../../assets/iconfont/iconfont.css";
.inner {
  padding: 0.35rem 0.3rem 0 0.3rem;
}
.tabbar {
  .iconfont:before {
    font-size: 0.6rem;
  }
}
.menu li {
  text-align: center;
  float: left;
  width: 25%;
  margin-bottom: 0.5rem;
  p {
    font-size: 0.36rem;
    margin-top: 0.22rem;
  }
  > div {
    margin-left: auto;
    margin-right: auto;
    width: 1.2rem;
    height: 1.2rem;
    line-height: 1.2rem;
    border-radius: 0.12rem;
    span {
      font-size: 0.75rem;
      color: #fff;
    }
    img {
      width: 100%;
    }
  }
}

.line {
  height: 0.23rem;
  background: #f5f5f5;
}
.menu ul {
  height: auto;
  overflow: hidden;
}
.menuPop {
  padding: 0.5rem 0 0 0;
  box-sizing: border-box;
  bottom: 1.33rem;
  li {
    text-align: center;
    float: left;
    width: 25%;
    margin-bottom: 0.5rem;
    > div {
      margin-left: auto;
      margin-right: auto;
      width: 1.2rem;
      height: 1.2rem;
      line-height: 1.2rem;
      border-radius: 0.12rem;
      span {
        font-size: 0.85rem;
      }
      img {
        width: 100%;
      }
    }
    p {
      font-size: 0.36rem;
      margin-top: 0.22rem;
    }
  }
  li:nth-child(1) span {
    color: #50b380;
    font-size: 0.92rem;
  }
  li:nth-child(2) span {
    color: #ee8332;
  }
  li:nth-child(3) span {
    color: #5094f2;
  }
  li:nth-child(4) span {
    color: #f1a33a;
  }
}
.tabbar {
  z-index: 200000000000;
}
.menubottom img {
  transition: 0.3s;
  height: 0.8rem;
}
.rotate img {
  transform: rotate(45deg);
}
.agreePop {
  overflow: hidden;
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
  padding: 0.4rem 0.3rem 0.35rem 0.3rem;
  .tit {
    font-size: 0.5rem;
    color: #222;
    margin-bottom: 0.7rem;
  }
  h2 {
    margin: 0.3rem 0;
    font-weight: normal;
    font-size: 0.42rem;
  }
  p {
    font-size: 0.36rem;
    color: #777;
    line-height: 0.65rem;
  }
  .body {
    overflow-y: auto;
    height: calc(100vh - 1.6rem);
  }
  .foot {
    height: 0.6rem;
  }
}
.jobPop {
  box-sizing: border-box;
  bottom: 1.33rem;
  min-height:5rem;
  .jobt {
    border-bottom: 1px solid #eee;
    padding: 0.3rem 0.35rem;
    margin-bottom: 0.3rem;
    .l {
      float: left;
      width: 0.6rem;
      i {
        font-size: 0.6rem;
        color: #666;
      }
    }
    .r {
      text-align: center;
      font-size: 0.42rem;
    }
  }
  .popBody {
    padding: 0.25rem 0.3rem 0.25rem 0.3rem;
    height: 5rem;
    overflow-y: auto;
    // .jobItem {
    //   margin-right: 0.2rem;
    //   margin-bottom: 0.22rem;
    //   font-size: 0.32rem;
    //   border: 1px solid #eee;
    //   padding: 0.16rem 0.23rem;
    //   display: inline-block;
    //   box-sizing: border-box;
    //   min-width: 1rem;
    // }
    .jobItem {
      float: left;
      border: 1px solid #eee;
      margin-left: 2%;
      width: calc(94% / 4);
      box-sizing: border-box;
      height: 0.75rem;
      text-align: center;
      line-height: 0.75rem;
      font-size: 0.32rem;
      margin-bottom: 0.22rem;
    }
    .jobItem:nth-child(4n + 1) {
      margin-left: 0;
    }
  }
}
</style>